import { memo } from "react";
import { Container, Row } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import { ImageAsyn } from "components/ImageAsyc";
import imagenPerson from "assets/image/person/img2.png";

const AcercaSection = () => {
  return (
    <Container>
      <Row>
        <Colxx md="6">
          <figure className="section_image align_right">
            <ImageAsyn src={imagenPerson} alt="" />
          </figure>
        </Colxx>
        <Colxx md="6">
          <div className="mad_section_container">
            <article>
              <h3 className="mad_title_style1">Bienvenido a</h3>
              <h2>Samy Spa</h2>
              <p
                className="h6 text-muted px-2"
                style={{ textAlign: "justify" }}
              >
                Se inicia en el trabajo del arte de la peluquería el año 1991,
                al mismo tiempo participando y capacitandose en diversos cursos
                y talleres dentro y fuera del país, el año 2005 es el
                seleccionado para representar a Bolivia en el Festival Baires
                2005en Buenos Aires Argentina 2007 e invitado a ser el técnico
                de la linea Silkey en La Paz, dictando varios seminarios y
                cursos para los clientes de Silkey, El año 2020 recive El Alto
                premio de parte de la Honorable Alcaldía Municipal de El Alto.
              </p>
              <div className="mad_author">
                <h3 className="mad_title_style2"> Samuel Salguero</h3>
                <span>Estilista</span>
              </div>
            </article>
          </div>
        </Colxx>
      </Row>
    </Container>
  );
};

export default memo(AcercaSection);
